import { camelCase, isEmpty, isNull, upperFirst } from 'lodash'
import React from 'react'
import loadable from '@loadable/component'

import { Section } from 'suf_storybook'

const CustomHtml = loadable(() => import('./customHtmlSection'))
const Highlight = loadable(() => import('./highlightSection'))
const IconTeaser = loadable(() => import('./iconTeaserSection'))
const IconTeaserGroup = loadable(() => import('./iconTeasersSection'))
const KeyPoint = loadable(() => import('./keyPointSection'))
const Showcase = loadable(() => import('./showcaseSection'))
const ImageGrid = loadable(() => import('./imageGridSection'))
const BoxGrid = loadable(() => import('./boxGridSection'))
const PageTeaser = loadable(() => import('./pageTeaserSection'))
const KeyHighlight = loadable(() => import('./KeyHighlightSection'))
const PromoHighlight = loadable(() => import('./promoHighlightSection'))

const InfoBlockMarkdownEditor = loadable(
  () => import('./infoBlockMarkdownSection')
)
const InfoBlockRichTextEditor = loadable(
  () => import('./infoBlockRichtextSection')
)

const supported = {
  CustomHtml,
  BoxGrid,
  PageTeaser,
  ImageGrid,
  Highlight,
  KeyHighlight,
  IconTeaser,
  IconTeaserGroup,
  KeyPoint,
  Showcase,
  InfoBlockMarkdownEditor,
  InfoBlockRichTextEditor,
  PromoHighlight
}

export type Props = {
  header?: { title: string }
  content?: object
  component: string
  customContent?: object
}

export default ({ content = [], component, customContent }: Props) => {
  const Component = supported[upperFirst(camelCase(component))]

  const notContent = isNull(content) || isEmpty(content)

  if (!Component || notContent) {
    return null
  }

  const isCustomHTML = component === 'Custom HTML'

  return (
    <>
      {isCustomHTML ? (
        <Component
          content={content}
          customContent={customContent}
          hideMoreSection
        />
      ) : (
        <Section className="section--scroll section--spacing-above section--spacing-below">
          <Component
            content={content}
            customContent={customContent}
            hideMoreSection
          />
        </Section>
      )}
    </>
  )
}
