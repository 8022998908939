/**
 *  Sections
 * Loops through each section
 *
 * Usage
 * - Abstracted this our of each template to configure the numbers better
 *
 *
 * @todo The number is set as -1 cause later in the chain some lib does stuff on it - I would refactor this
 */
import React from 'react'
import _map from 'lodash/map'
import uniqid from 'uniqid'

import Section, { Props as SectionProps } from './section'

import { Query } from '../generated/gatsby.types'

export type Props = {
  data: Query
  researchData?: any
  sections: Omit<SectionProps, 'data' | 'index'>[]
  customContent: any
  marketSector: any
}

// Use React.memo because this component is so large that unnecessary rerenders
// have a noticeable performance impact
export default React.memo(
  ({ data, sections, researchData, customContent, marketSector }: Props) => {
    let number = -1
    return (
      <>
        {_map(sections, (props) => {
          if (props.title) {
            number += 1
          }
          const { component } = props
          return (
            <Section
              key={uniqid()}
              index={number}
              data={data}
              {...props}
              researchData={researchData}
              customContent={component === 'Custom HTML' ? customContent : {}}
              marketSector={marketSector}
            />
          )
        })}
      </>
    )
  }
)
