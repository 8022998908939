/**
 * Template - Market Sector
 *
 * Usage
 * - http://localhost:8000/
 */
import { graphql } from 'gatsby'
import React, { useContext } from 'react'
import { SFHero } from 'suf_storybook'
import { Query } from '../generated/gatsby.types'
import { OverlayContext } from '../components/OverlayContext'
import Main from '../components/main'
import removeEmptySections from '../lib/removeEmptySections'
import Sections from '../components/sections'
import SEO from '../components/seo'
import parseBannerProps from '../lib/parseBannerProps'

type Props = {
  data: Query
  pageContext: any
}

export default ({ data, pageContext }: Props) => {
  const { hasMarketo } = useContext(OverlayContext)
  const { customContent, slug } = pageContext
  const { contentfulMarketSector } = data
  const { sections, seoTitle, seoDescription, noIndex } =
    contentfulMarketSector!
  const MarketSectorSections = removeEmptySections(data, sections)

  const typeName = contentfulMarketSector?.__typename || ''
  return (
    <Main>
      <SEO
        title={seoTitle || 'Sustainable Fitch'}
        description={
          seoDescription || data.contentfulMarketSector?.banner?.description
        }
        noIndex={noIndex}
        type={typeName}
        keywords={[`sustainable fitch`, seoTitle]}
      />
      <SFHero
        {...parseBannerProps(data.contentfulMarketSector?.banner)}
        hasMarketo={hasMarketo}
        isActionBarOpen={data.contentfulMarketSector?.isActionBarOpen}
      />
      <Sections
        data={data}
        sections={MarketSectorSections}
        customContent={customContent}
        marketSector={slug}
      />
    </Main>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulMarketSector(slug: { eq: $slug }) {
      __typename
      banner {
        ...ContentfulBannerFragment
      }
      sections {
        __typename
        ... on Node {
          ...ContentfulStaticSectionsFragment
          ...ContentfulDynamicSectionsFragment
        }
      }
      seoTitle
      seoDescription
      noIndex
      slug
    }
    allContentfulEvent(
      filter: {
        node_locale: { eq: "en-US" }
        metadata: {
          tags: { elemMatch: { contentful_id: { eq: "sustainableFitch" } } }
        }
      }
      sort: { fields: [startDate], order: ASC }
    ) {
      ...ContentfulEvents
    }
  }
`
