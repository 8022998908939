import React from 'react'

// eslint-disable-next-line import/no-extraneous-dependencies

import { generateSectionHeader } from '../lib'

import { Query } from '../generated/gatsby.types'

import StaticSection from './sectionStatic'
import DynamicSection from './sectionDynamic'

const deriveConfigFromComponent = (component: string) => {
  if (
    component === 'Key Figure' ||
    component === 'Key Point' ||
    component === 'Page Teaser' ||
    component === 'Videos' ||
    component === 'Events'
  ) {
    return { hasWrapper: false }
  }

  return {
    hasWrapper: true,
    wrapperClassName: 'wrapper--1'
  }
}

export type researchHighlightImageProps = {
  file: {
    url: string
  }
}

export type Props = {
  __typename: string
  component: string
  content?: object
  dynamicSectionContent?: Object
  data: Query
  researchData: any
  index: number
  researchHighlight?: string
  researchHighlightCustomTitle?: string
  researchHighlightImage?: researchHighlightImageProps
  title?: string
  cta?: string
  link?: string
  marketoFormId?: string
  customContent: any
  marketSector: any
}

export default ({
  __typename,
  component,
  content,
  dynamicSectionContent,
  data,
  title,
  customContent,
  researchData,
  researchHighlight,
  researchHighlightCustomTitle,
  researchHighlightImage,
  cta,
  link,
  marketoFormId,
  marketSector
}: Props) => {
  const props = {
    component,
    content: content || dynamicSectionContent,
    config: deriveConfigFromComponent(component),
    header: generateSectionHeader(title),
    customContent,
    researchData,
    researchHighlight: researchHighlight || '',
    researchHighlightCustomTitle,
    researchHighlightImage,
    cta,
    link,
    marketoFormId,
    marketSector
  }

  if (__typename.includes('Static')) {
    return (
      <StaticSection
        content={content}
        {...props}
        customContent={customContent}
      />
    )
  }
  return <DynamicSection data={data} {...props} researchData={researchData} />
}
