import _, { get } from 'lodash'
import React from 'react'
import { graphql as gatsbyGraphql, useStaticQuery } from 'gatsby'
import loadable from '@loadable/component'
import { ResearchHighlightImage, Section } from 'suf_storybook'
import { ContentfulImage, Query } from '../generated/gatsby.types'
import { getReportTypes, getReportTypesSearchNodeSlugs } from '../lib'

const Research = loadable(() => import('./researchSection'))
const Events = loadable(() => import('./eventSection'))

type ImageProps = {
  file: ResearchHighlightImage
}
let langSlug: string
const supported = {
  Research,
  Events,
  InsightsPlus: Research
}

const key = {
  Research: null,
  Events: 'Event',
  'Insights Plus': null
}

const titleNotNeededComponents = ['events']
const pageContentKeyAndTitle = (data: Query) => {
  const contentParentKey: any = _.findKey(data, (entry) => _.has(entry, 'slug'))
  const { title } = data[contentParentKey]
  return [contentParentKey, encodeURI(title)]
}

const deriveContentFromData = (
  component: string,
  content: any,
  data: Query,
  researchHighlight: string,
  researchHighlightCustomTitle: string,
  researchHighlightImage?: ImageProps,
  cta?: string,
  link?: string,
  marketSector?: string
) => {
  const defaultResearchImageQueryData = useStaticQuery(
    defaultResearchHighlightImage
  )
  const contentfulImage: ContentfulImage = _.get(
    defaultResearchImageQueryData,
    'contentfulImage',
    undefined
  )
  const defaultHiglightImage: ResearchHighlightImage = {
    url: _.get(contentfulImage, 'image.fluid.src', undefined)
  }

  const researchImage =
    _.get(researchHighlightImage, 'file', undefined) || defaultHiglightImage
  let moreResearchLinkTitle: any = null
  let moreResearchLinkUrl: string | null = null

  // API content
  if (_.isNull(key[component])) {
    const insightsPlusReportTypes =
      component === 'Insights Plus' ? getReportTypes(content) : []
    const insightsPlusReportTypeSlug =
      component === 'Insights Plus'
        ? getReportTypesSearchNodeSlugs(content)
        : ''
    moreResearchLinkTitle = cta
    const displayView =
      content && content !== null ? content[0].reportsView : ''
    moreResearchLinkUrl = _.get(link, 'link', undefined)

    return {
      researchHighlight,
      researchHighlightCustomTitle,
      researchImage,
      component,
      content,
      displayView,
      insightsPlusReportTypes,
      insightsPlusReportTypeSlug,
      data,
      moreResearchLinkTitle,
      moreResearchLinkUrl,
      marketSector,
      link
    }
  }

  return get(data, `allContentful${key[component]}.nodes`, [])
}

export type Props = {
  component: string
  config: object
  content?: object
  data: Query
  researchData: object
  header?: { title: string; number: string }
  limit?: number
  researchHighlight?: string
  researchHighlightCustomTitle?: string
  researchHighlightImage?: ImageProps
  cta?: string
  link?: string
  marketoFormId?: string
  marketSector?: string
}
export default ({
  component,
  config,
  content = [],
  data,
  researchData,
  researchHighlight = '',
  researchHighlightCustomTitle = '',
  researchHighlightImage,
  limit,
  header,
  cta,
  link,
  marketSector
}: Props) => {
  const Component = supported[_.upperFirst(_.camelCase(component))]
  if (!Component) {
    return null
  }

  const hideTitle =
    titleNotNeededComponents.indexOf(_.lowerCase(component)) !== -1

  const derivedContent = deriveContentFromData(
    component,
    content,
    data,
    researchHighlight,
    researchHighlightCustomTitle,
    researchHighlightImage,
    cta,
    link,
    marketSector
  )

  return (
    <Section
      className="section--scroll section--spacing-above section--spacing-below"
      headerClassName="heading--1"
      hideNumber
      hideTitle={hideTitle}
      header={header}
      {...config}
    >
      <Component
        limit={limit}
        content={derivedContent}
        slug={data.slug}
        languageSlug={langSlug}
        researchData={researchData}
        pageContentKeyAndTitle={pageContentKeyAndTitle(data)}
      />
    </Section>
  )
}

export const defaultResearchHighlightImage = gatsbyGraphql`
   query researchDefaultImageQuery {
    contentfulImage(description: {eq: "Default SF Research Highlight Image"}) {
     ...ContentfulImageFragment
    }
  }
`
